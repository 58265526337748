import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  width: '100%',

  '> h3:first-child': {
    fontWeight: 500,
    margin: 0,
    padding: '0 $4 $4 $4',
  },
})

const DataContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  gap: '$1',

  variants: {
    state: {
      true: {
        background: '$backgroundPrimary',
        borderRadius: '$medium',
        minHeight: '$$height',
      },
      false: {},
    },
  },
})

export { Container, DataContainer }
