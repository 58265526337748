interface ResellerFidelity {
  value: string
}

interface Reseller {
  club?: ResellerFidelity
  isDefaulter: boolean
  isBlocked: boolean
  activeCycle: string
  birthday: string
  cpf: string
  cs: string
  geraId: string
  rg: string
  name: string
  overdue: boolean
  firstOrderCycle: string
  actionStatus: ResellerAttendanceStatus
}

enum ResellerAttendanceStatus {
  ATTENDANCE_RELEASED = 'ATTENDANCE_RELEASED',
  NOT_IN_STRUCTURE = 'NOT_IN_STRUCTURE',
  WITHOUT_STRUCTURE = 'WITHOUT_STRUCTURE',
  IN_ATTENDANCE_BY_OTHER_SUPERVISOR = 'IN_ATTENDANCE_BY_OTHER_SUPERVISOR',
  IN_ATTENDANCE_BY_YOURSELF = 'IN_ATTENDANCE_BY_YOURSELF',
}

export { ResellerAttendanceStatus, type Reseller }
