/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FilterField } from '@shared/providers'

import {
  CheckboxField,
  DateRangeField,
  DropdownField,
  DropdownFieldAsync,
  InputField,
  SwitchField,
} from '../../components'

import { type ObjectivesFormHandler } from '../../objectives-filter.types'
import { Container } from './filter-fields.styles'

interface FilterFieldsProps extends ObjectivesFormHandler<any> {
  fields: FilterField[]
}

const FilterFields = ({ fields, ...props }: FilterFieldsProps): JSX.Element => {
  return (
    <Container>
      {fields.map((field, index) => {
        const disabled = field.isDisabled?.(props.form) ?? false

        switch (field.type) {
          case 'dropdown':
            return <DropdownField key={index} disabled={disabled} {...field} {...props} />
          case 'dropdown-async':
            return <DropdownFieldAsync key={index} disabled={disabled} {...field} {...props} />
          case 'date-range':
            return <DateRangeField key={index} disabled={disabled} {...field} {...props} />
          case 'checkbox':
            return <CheckboxField key={index} disabled={disabled} {...field} {...props} />
          case 'switch':
            return <SwitchField key={index} disabled={disabled} {...field} {...props} />
          case 'input':
            return <InputField key={index} disabled={disabled} {...field} {...props} />
          default:
            return null
        }
      })}
    </Container>
  )
}

export { FilterFields }
