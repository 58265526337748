import { Text } from '@grupoboticario/flora-react'

import { useApiData } from '@shared/api'
import { DataBlock, DataSection, List } from '@shared/components'
import { pt } from '@shared/locales'
import { Fragment } from 'react'

import { PromotionItem } from './promotion-item'
import { type PromotionsDTO, type PromotionsProps } from './promotions.types'

const Promotions = ({ resellerId }: PromotionsProps): JSX.Element => {
  const { promotions } = pt

  const {
    data: model,
    error,
    filled,
    loading,
    getData,
  } = useApiData<PromotionsDTO>({
    dataSource: 'getResellerAdditionalInfo',
    params: { resellerId },
  })

  return (
    <DataSection
      data={model}
      filled={filled}
      loading={loading}
      error={error}
      onRetry={getData}
      fallbackHeight={306}
      render={(data) => (
        <DataBlock
          description={promotions.title}
          content={
            <Fragment>
              {data.campaigns && data.campaigns.length <= 0 && (
                <Text as="p" size="bodyLargeStandardMedium" color="$alert-standard">
                  {promotions.notEnoughData}
                </Text>
              )}

              {data.campaigns && data.campaigns.length > 0 && (
                <List
                  type="accordion"
                  data={data.campaigns}
                  renderItem={(c) => <PromotionItem name={c.name} promotions={c.promotions} />}
                />
              )}
            </Fragment>
          }
        />
      )}
    />
  )
}

export { Promotions, type PromotionsProps }
