import { Avatar } from './avatar'
import { Content } from './content'
import { Container, ContentContainer } from './data-block.styles'
import { Description, type DescriptionProps } from './description'

interface DataBlockProps extends DescriptionProps {
  content: JSX.Element | string
  textToCopy?: string
  copy?: boolean
  avatar?: boolean
}

const DataBlock = ({
  descriptionTooltip,
  description,
  content = '---',
  avatar,
  textToCopy,
  copy,
}: DataBlockProps): JSX.Element => {
  const isContentString = typeof content === 'string'

  return (
    <Container avatar={!!avatar} content={isContentString ? 'string' : 'element'} tabIndex={0}>
      {avatar && isContentString && <Avatar content={content} />}

      <ContentContainer>
        <Description description={description} descriptionTooltip={descriptionTooltip} />
        <Content description={description} content={content} copy={copy} textToCopy={textToCopy} />
      </ContentContainer>
    </Container>
  )
}

export { DataBlock }
