import React, { Fragment } from 'react'

import { FloraButton as Button, Spinner } from '@grupoboticario/flora-react'
import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { useApiData } from '@shared/api'
import { DataBlock } from '@shared/components'
import { pt } from '@shared/locales'

import { ResellerCreditDTO } from '@reseller-list/reseller-list.types'

interface CreditProps {
  resellerId?: string
}

const Credit: React.FC<CreditProps> = ({ resellerId }) => {
  const { data, error, filled, loading, getData } = useApiData<ResellerCreditDTO>({
    dataSource: 'getResellerCredit',
    params: { resellerId },
  })

  return (
    <DataBlock
      description={pt.resellerList.detailReCredit}
      descriptionTooltip={pt.resellerList.detailReCreditTooltip}
      content={
        <Fragment>
          {loading && <Spinner data-testid="loading" size="xs" />}
          {filled && toCurrency(data?.availableCredit)}
          {error && (
            <Button size="small" hierarchy="secondary" onClick={getData}>
              {pt.common.errorState.buttonText}
            </Button>
          )}
        </Fragment>
      }
    />
  )
}

export { Credit }
