import { type IHttpResult } from '@grupoboticario/vdi-mfe-utils'

interface API {
  getResellerList: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getResellerCredit: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getReseller: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getSectors: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getResellerAdditionalInfo: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getTopPurchasedProducts: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getCities: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getZonesER: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getCS: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getFidelityRange: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  updateResellerData: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getPromotions: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getBrandsBought: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getAppAdhesion: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getHarvestOptions: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getCurrentCycles: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  registerActivations: <T>(body: unknown) => Promise<IHttpResult<T, unknown>>
  getMLDLink: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
  getReasonsActivationRecord: <T>(params: APIParams) => Promise<IHttpResult<T, unknown>>
}

type APIParams = Record<string, string | number | number[] | string[] | boolean>

enum MetaState {
  Idle,
  Loading,
  Error,
  Fullfilled,
}

interface AsyncState {
  filled: boolean
  error: boolean
  loading: boolean
  idle: boolean
}

export { MetaState, type API, type APIParams, type AsyncState }
