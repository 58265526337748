/* eslint-disable @typescript-eslint/no-explicit-any */

import { format } from 'date-fns'
import { forwardRef, useEffect, useId, useRef, useState } from 'react'

import ReactDatePicker, {
  CalendarContainer,
  registerLocale,
  type ReactDatePickerCustomHeaderProps,
} from 'react-datepicker'

import { ptBR } from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'

import { FloraButton as Button, Text } from '@grupoboticario/flora-react'
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from '@grupoboticario/flora-react-icons'

import { pt } from '@shared/locales'

import {
  CustomCalendarContainerProps,
  CustomInputProps,
  DatePickerProps,
  DateStateProps,
  DateValue,
} from './datepicker.types'

import { CalendarHeader, Container, HeaderContainer, InputContainer } from './datepicker.styles'

registerLocale('pt-BR', ptBR)

const DatePicker: React.FC<DatePickerProps> = ({
  startDate,
  endDate,
  selected,
  disabled,
  labelText,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [range, setRange] = useState<DateStateProps>({ start: null, end: null })
  const [open, setOpen] = useState(false)

  const handleChange = (date: DateValue): void => {
    props.onChange(date)

    if (props.selectsRange) {
      const [start, end] = date as [Date | null, Date | null]
      setRange({ start, end })
    }
  }

  useEffect(() => {
    setRange({ start: startDate, end: endDate })
  }, [startDate, endDate])

  useEffect(() => {
    const outsideClick = (ev: MouseEvent) => {
      const container = containerRef?.current
      const node = ev.target as Node
      if (container && !container.contains(node)) setOpen(false)
    }

    document.addEventListener('click', outsideClick, true)
    return () => {
      document.removeEventListener('click', outsideClick, true)
    }
  })

  const openCalendar = () => {
    setOpen(true)
  }

  const closeCalendar = () => {
    setOpen(false)
  }

  return (
    <Container ref={containerRef}>
      <ReactDatePicker
        {...props}
        open={open}
        disabled={disabled}
        onCalendarOpen={openCalendar}
        onCalendarClose={closeCalendar}
        onClickOutside={closeCalendar}
        onFocus={openCalendar}
        showPopperArrow={false}
        renderCustomHeader={CustomPickerHeader}
        customInput={<CustomInput label={labelText} />}
        openToDate={range.start ?? selected ?? new Date()}
        startDate={range.start}
        endDate={range.end}
        selected={selected}
        onChange={handleChange}
        locale="pt-BR"
        dateFormat="dd/LLL"
        popperPlacement="bottom-start"
        calendarContainer={(calendar) => (
          <CustomCalendarContainer
            isRange={props.selectsRange}
            hasStartValue={Boolean(range.start)}
            {...calendar}
          />
        )}
      />
    </Container>
  )
}

const CustomInput = forwardRef(({ label, disabled, ...props }: CustomInputProps, ref: any) => {
  const id = useId()

  return (
    <InputContainer disabled={disabled} hasValue={Boolean(props.value)} {...props}>
      <label htmlFor={id}>{label}</label>
      <input
        {...props}
        ref={ref}
        id={id}
        data-testid="datepicker-input"
        aria-disabled={disabled}
        disabled={disabled}
      />
      <CalendarIcon />
    </InputContainer>
  )
})

CustomInput.displayName = 'CustomInput'

const CustomCalendarContainer = ({
  className,
  children,
  isRange,
  hasStartValue,
}: CustomCalendarContainerProps): JSX.Element => {
  return (
    <CalendarContainer data-testid="datepicker-calendar" className={className}>
      <CalendarHeader>
        <Text color="$nonInteractiveAltPredominant" size="exceptionsAuxiliarRegular">
          {pt.datepicker.selectDate(hasStartValue, isRange).trim()}
        </Text>
      </CalendarHeader>
      {children}
    </CalendarContainer>
  )
}

const CustomPickerHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps): JSX.Element => (
  <HeaderContainer align="center">
    <Button
      hierarchy="tertiary"
      styleSemantic="neutral"
      surfaceColor="dark"
      data-testid="datepicker-decrease-month"
      onClick={(e: any) => {
        e.preventDefault()
        decreaseMonth()
      }}
      disabled={prevMonthButtonDisabled}
    >
      <ArrowLeftIcon color="$actionableDefault" />
    </Button>
    <Text
      data-testid="datepicker-month-label"
      css={{ textTransform: 'capitalize ' }}
      size="bodyLargeStandardMedium"
      color="$nonInteractivePredominant"
    >
      {format(date, 'LLLL', { locale: ptBR })}
    </Text>
    <Button
      hierarchy="tertiary"
      styleSemantic="neutral"
      surfaceColor="dark"
      data-testid="datepicker-increase-month"
      onClick={(e: any) => {
        e.preventDefault()
        increaseMonth()
      }}
      disabled={nextMonthButtonDisabled}
    >
      <ArrowRightIcon color="$actionableDefault" />
    </Button>
  </HeaderContainer>
)

export { DatePicker }
