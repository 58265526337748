import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  paddingBottom: '$4',

  '& [data-flora="input-wrapper"]': {
    minHeight: 'unset',
  },

  '& [data-flora="multiple-select"]': {
    paddingTop: '$5 !important',
  },
})

export { Container }
