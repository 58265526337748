import { AsyncState, MetaState, useApi, useAsyncState } from '@shared/api'
import { useEvents } from '@shared/events'

import { RegisterContextProps, useRegisterContext } from '../providers'

interface UseRegister extends AsyncState, Pick<RegisterContextProps, 'dispatch'> {
  isValid: boolean
  text: string
  finish: () => Promise<boolean>
}

const useRegister = (): UseRegister => {
  const events = useEvents()
  const { state, dispatch } = useRegisterContext()
  const status = useAsyncState(state.status)
  const api = useApi()
  const isValid =
    (state.success && state.text != undefined) || (!state.success && state.text.length >= 15)

  const finish = async () => {
    if (!isValid) {
      return false
    }

    const textField = state.success
      ? {
          reason: {
            label: state.text,
            id: state.id,
          },
        }
      : { failReason: state.text }

    const activation = { success: state.success, resellers: state.resellers, ...textField }

    if (state.success) {
      events.clickRegister('click')
    } else {
      events.clickRegister('observation', state.text.slice(0, 80).trim())
    }

    dispatch({ type: 'change-status', payload: MetaState.Loading })
    const result = await api.registerActivations(activation)

    if (result.ok) {
      if (activation.reason?.label) {
        events.clickRegisterReason(activation.reason.label)
      }

      dispatch({ type: 'change-status', payload: MetaState.Fullfilled })
      dispatch({ type: 'reset-state' })
    } else {
      dispatch({ type: 'change-status', payload: MetaState.Error })
    }

    return result.ok
  }

  return { isValid, finish, dispatch, text: state.text, ...status }
}

export { useRegister }
