import { Fragment } from 'react'

import { CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import { ErrorState } from '../error-state'
import { LoadingState } from '../loading-state'

import { Actions } from './actions'
import { Container, StateContainer, Table } from './data-table.styles'
import { type DataTableProps } from './data-table.types'
import { Filters } from './filters'
import { Heading } from './heading'
import { TableBody } from './table-body'
import { TableHeader } from './table-header'
import { TablePagination } from './table-pagination'
import { TableProvider } from './table-provider'

const DataTable = <T,>(props: DataTableProps<T>): JSX.Element => (
  <TableProvider {...props}>
    <Container>
      <Heading />
      {props.filter?.showTags && <Filters />}
      {props.slots?.middle}
      {props.slots?.bottomMiddle}
      <Actions />

      {props.state.loading && (
        <StateContainer>
          <LoadingState />
        </StateContainer>
      )}

      {props.state.error && (
        <StateContainer>
          <ErrorState
            icon={<CrossCircleIcon size="70px" color="$error-standard" />}
            onButtonClick={props.state.onErrorBtnClick}
          />
        </StateContainer>
      )}

      {props.state.filled && (
        <Fragment>
          <Table>
            <TableHeader />
            <TableBody />
          </Table>

          <TablePagination />
        </Fragment>
      )}
    </Container>
  </TableProvider>
)

export { DataTable }
