import React, { Fragment } from 'react'

import { Grid } from '@grupoboticario/flora-react'

import { useApiData } from '@shared/api'
import { DataBlock, DataSection, StatusLabel } from '@shared/components'
import { pt } from '@shared/locales'

import { PromotionsDTO } from '../promotions'

import { ResellerMLDLinkDTO } from '../../../reseller-list.types'
import { Credit } from './credit'
import { ResellerAttendanceStatus, type Reseller } from './general.types'
import { Situation } from './situation'

interface GeneralProps {
  resellerId: string
  lastTabulation: string
  currentCycle: string
}

const General: React.FC<GeneralProps> = ({ currentCycle, lastTabulation, resellerId }) => {
  const {
    data: resellerData,
    error: resellerError,
    filled: resellerFilled,
    loading: resellerLoading,
    getData: resellerGetData,
  } = useApiData<Reseller>({
    dataSource: 'getReseller',
    params: { resellerId },
  })

  const {
    data: additionalInfoData,
    error: additionalInfoError,
    filled: additionalInfoFilled,
    loading: additionalInfoLoading,
    getData: additionalInfoGetData,
  } = useApiData<PromotionsDTO>({
    dataSource: 'getResellerAdditionalInfo',
    params: { resellerId },
  })

  const {
    data: mldLinkInfoData,
    filled: mldLinkInfoFilled,
    loading: mldLinkInfoLoading,
    getData: mldLinkInfoGetData,
  } = useApiData<ResellerMLDLinkDTO>({
    dataSource: 'getMLDLink',
    params: { resellerId },
  })

  const onRetry = (): void => {
    resellerGetData()
    additionalInfoGetData()
    mldLinkInfoGetData()
  }

  return (
    <Fragment>
      <Situation
        isBlocked={resellerData?.isBlocked}
        status={resellerData?.actionStatus}
        canAttend={canReleaseAttendance(resellerData?.actionStatus)}
      />

      <DataSection
        data={{ ...resellerData, ...additionalInfoData, ...mldLinkInfoData }}
        error={resellerError || additionalInfoError}
        filled={resellerFilled && additionalInfoFilled}
        loading={resellerLoading || additionalInfoLoading}
        onRetry={onRetry}
        fallbackHeight={376}
        render={(reseller) => {
          return (
            <Fragment>
              <Grid templateColumns="1fr" gap="$1">
                <DataBlock
                  description={pt.resellerList.detailReName}
                  content={reseller.name || ''}
                  avatar
                />
              </Grid>

              <Grid templateColumns="4fr 4fr" gap="$1">
                <DataBlock
                  description={pt.resellerList.detailReCode}
                  content={reseller.geraId || ''}
                  copy
                />

                <DataBlock
                  description={pt.resellerList.currentCycle}
                  content={currentCycle}
                  descriptionTooltip={pt.resellerList.currentCycleTooltip}
                />

                <DataBlock
                  description={pt.resellerList.detailReCycle}
                  content={reseller.activeCycle || ''}
                />

                <DataBlock
                  description={pt.resellerList.detailOpeningReCycle}
                  content={reseller.qtCyclesInactive || ''}
                />

                <DataBlock
                  description={pt.resellerList.detailFidelityTitle}
                  content={reseller.club?.value ?? ''}
                />
                <DataBlock
                  description={pt.resellerList.detailDefaulterTitle}
                  content={
                    <StatusLabel status={reseller.isDefaulter ? 'error' : 'success'}>
                      {reseller.isDefaulter
                        ? pt.resellerList.detailDefaulterAnswer.yes
                        : pt.resellerList.detailDefaulterAnswer.no}
                    </StatusLabel>
                  }
                />
              </Grid>

              <Grid templateColumns="2fr 2fr" gap="$1">
                <Credit resellerId={reseller.geraId} />

                <DataBlock
                  description={pt.resellerList.detailLastTabulation}
                  content={lastTabulation || '---'}
                />
              </Grid>

              {!mldLinkInfoLoading && mldLinkInfoFilled && (
                <Grid templateColumns="1fr" gap="$1">
                  <DataBlock
                    description={pt.resellerList.mld}
                    content={reseller.mldLink || ''}
                    textToCopy={`${reseller.mldLink}?utm_medium=VDI_info_complementares&utm_source=VDI`}
                    copy
                  />
                </Grid>
              )}
            </Fragment>
          )
        }}
      />
    </Fragment>
  )
}

const canReleaseAttendance = (actionStatus?: ResellerAttendanceStatus): boolean =>
  actionStatus === ResellerAttendanceStatus.ATTENDANCE_RELEASED ||
  actionStatus === ResellerAttendanceStatus.IN_ATTENDANCE_BY_YOURSELF

export { General }
