/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type ReactNode } from 'react'

import { Text } from '@grupoboticario/flora-react'

import { ErrorState } from '../error-state'
import { LoadingState } from '../loading-state'

import { Container, DataContainer } from './data-section.styles'

interface DataSectionProps<T> extends DataSectionStateProps {
  title?: ReactNode
  fallbackHeight?: number
  data: T
  render: (data: Exclude<T, undefined | null>) => ReactNode
}

interface DataSectionStateProps {
  loading?: boolean
  filled?: boolean
  error?: boolean
  onRetry?: () => void
}

const DataSection = <T,>({
  title,
  error,
  loading,
  filled = true,
  fallbackHeight,
  render,
  data,
  onRetry,
  ...others
}: DataSectionProps<T>): JSX.Element => {
  return (
    <Container {...others}>
      {title && (
        <Text as="h3" size="bodyLargeStandardMedium" color="$nonInteractivePredominant">
          {title}
        </Text>
      )}

      <DataContainer css={{ $$height: `${fallbackHeight}px` }} state={loading || error}>
        {loading && <LoadingState />}
        {error && <ErrorState onButtonClick={onRetry} />}
        {filled && render(data as Exclude<T, undefined | null>)}
      </DataContainer>
    </Container>
  )
}

export { type DataSectionStateProps, DataSection }
