import { styled } from '@grupoboticario/flora-react'

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  borderRadius: '$medium',
  backgroundColor: '$nonInteractiveAltPredominant !important',
  outlineWidth: '2px',
  outlineStyle: 'solid',
  outlineColor: 'transparent',
  paddingTop: '$4',
  paddingBottom: '$4',
  paddingRight: '$8',
  transitionProperty: 'outline-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',
  width: '100%',

  '&:focus-visible': {
    outlineColor: '$brand-1',
  },

  variants: {
    avatar: {
      true: {
        paddingLeft: '96px',
      },
      false: {
        paddingLeft: '$8',
      },
    },

    content: {
      string: {
        // height: '$18',
      },
      element: {
        minHeight: '$18',
      },
    },
  },
})

const ContentContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export { Container, ContentContainer }
