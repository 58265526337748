import { Hidden } from '@grupoboticario/flora-react'

import { useTableBodyContext, useTableContext } from '../table-provider'

import {
  BodyCellBtn,
  BodyCellCheckbox,
  BodyCellStatus,
  BodyCellText,
  BodyCurrencyText,
} from './body-cell'

import { TableRow } from './table-body.styles'

const TableBody = (): JSX.Element => {
  const { records, columns, onCheckRecord } = useTableContext()
  const { recordsChecked, setRecordsChecked } = useTableBodyContext()

  const checkRecord = (record: unknown) => () => {
    if (recordsChecked.includes(record)) {
      setRecordsChecked((rc) => rc.filter((r) => r !== record))
    } else {
      onCheckRecord?.()
      setRecordsChecked((rc) => [...rc, record])
    }
  }

  return (
    <tbody>
      {records.map((record, rIndex) => {
        const checked = recordsChecked.includes(record)

        return (
          <TableRow key={rIndex}>
            <BodyCellCheckbox onCheck={checkRecord(record)} checked={checked} />

            {columns.map((column, cIndex) => {
              let cell = null

              switch (column.type) {
                case 'currency':
                  cell = <BodyCurrencyText record={record} column={column} checked={checked} />
                  break
                case 'text':
                  cell = <BodyCellText record={record} column={column} checked={checked} />
                  break
                case 'btn':
                  cell = <BodyCellBtn record={record} column={column} />
                  break
                case 'status':
                  cell = <BodyCellStatus record={record} column={column} />
                  break
                default:
                  break
              }

              return (
                <Hidden only={column.hidden} key={cIndex}>
                  {cell}
                </Hidden>
              )
            })}
          </TableRow>
        )
      })}
    </tbody>
  )
}

export { TableBody }
