import { useMemo } from 'react'

import { FloraButton as Button, Text } from '@grupoboticario/flora-react'
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons'

import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import { DataTableTextColumn } from '../data-table.types'
import { useTableBodyContext, useTableContext } from '../table-provider'
import { ActionsContainer } from './actions.styles'

const Actions = <T,>(): JSX.Element | null => {
  const events = useEvents()
  const { recordsChecked, setRecordsChecked } = useTableBodyContext<T>()
  const { columns, actions, onCopyRecords } = useTableContext<T>()

  const copiablesColumns = useMemo(
    () => columns.filter((c) => (c.type === 'text' || c.type === 'currency') && c.copyable),
    [columns],
  ) as DataTableTextColumn<unknown>[]

  const getRecordContent = (r: T) =>
    copiablesColumns.map((c) => c.onBeforeCopy?.(r[c.key]) ?? r[c.key]).join('\t')

  const copyRecords = async (): Promise<void> => {
    onCopyRecords?.()
    const content = recordsChecked.map(getRecordContent).join('\n')
    await navigator.clipboard.writeText(content)
    actions.onCopyData?.(recordsChecked)
    setRecordsChecked([])
  }

  if (!recordsChecked.length) {
    return null
  }

  const onClickButton = (): void => {
    copyRecords()
    events.clickCopyRecordsOpenModal()
  }

  return (
    <ActionsContainer>
      <Text size="exceptionsAuxiliarMedium" color="$nonInteractiveAuxiliar" weight="medium">
        {pt.actions.checkedRecords(recordsChecked.length)}
      </Text>

      <Button
        onClick={onClickButton}
        hierarchy="secondary"
        size="small"
        has="iconLeft"
        icon={<TwoPiledSquaresIcon />}
        aria-label={pt.actions.ariaLabelCopyRecords}
      >
        {pt.actions.copyRecords}
      </Button>
    </ActionsContainer>
  )
}

export { Actions }
